<template>
  <div class="loading">
    <img src="/imgs/loading-svg/loading-bars.svg" alt="" />
  </div>
</template>
<script>
export default {
  name: "loading",
};
</script>
<style lang="scss">
.loading {
  text-align: center;
  img {
    width: 150px;
    height: 150px;
  }
}
</style>
